import React, { useEffect, useRef, useState, useMemo } from 'react'
import {
    Button,
    Divider,
    Typography,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    CircularProgress,
} from '@mui/material'
import { isEmpty, pickBy, get, sortBy } from 'lodash-es'
import {
    SelectInput,
    useGetMany,
    useGetList,
    useGetOne,
    useEditContext,
    useCreateContext,
    useRecordContext,
    AutocompleteInput,
} from 'react-admin'
import {
    CheckCircle,
    Error,
    Warning,
    PendingOutlined,
    CancelOutlined,
} from '@mui/icons-material'
import cronstrue from 'cronstrue'
import parser from 'cron-parser'
import { nextSyncTime, formatDate } from '@thefront/pandipackV2'
import { API_URI, HEADERS } from '../appConfigs'
import { useFormContext } from 'react-hook-form'

//--------------------------------------------------- COMPONENTS --------------------------------------------------- //
export const SCHEDULEMAP = {
    '': 'Select Option',
    '*/30 * * * *': 'Every 30 minutes',
    '5 */1 * * *': 'Every 1 Hour',
    '10 */4 * * *': 'Every 4 Hours',
    '15 */6 * * *': 'Every 6 Hours',
    '20 */12 * * *': 'Every 12 Hours',
    '0 1 * * *': 'Once Per Day',
}

export const CronLabel = (props) => {
    // returns true if input value exists in schedule menu
    const getSchedule = (choices, input) => {
        if (choices[input]) {
            return choices[input]
        }
        return input
    }
    const record = useRecordContext(props)

    return (
        <div>
            {<div>{record && getSchedule(props.choices, record.schedule)}</div>}
        </div>
    )
}

export const FilterSelectInput = ({ setFilters, filterValues, ...props }) => {
    return (
        <SelectInput
            emptyText={'Select One'}
            {...props}
            variant="outlined"
            sx={{
                '& > div.MuiInputBase-root': {
                    height: '38px',
                    borderRadius: 0,
                    width: 'auto',
                    '& > div': {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    '&:hover': {
                        '& > fieldset': {
                            border: '2px solid black',
                        },
                    },
                },
                minWidth: '150px',
            }}
            onChange={(e) =>
                setFilters({ ...filterValues, [props.source]: e.target.value })
            }
        />
    )
}

const matchAutoCompleteSuggestion = (filter, choice) => {
    return choice.name.toLowerCase().includes(filter.toLowerCase())
}

export const FilterAutoCompleteInput = ({ setFilters, ...props }) => {
    return (
        <AutocompleteInput
            {...props}
            variant="outlined"
            sx={{
                '& .MuiInputBase-root': {
                    height: '38px',
                    borderRadius: 0,
                    width: 'auto',
                    '& .MuiInputBase-input': {
                        fontSize: '13px',
                        fontWeight: 'bold',
                    },
                    '&:hover': {
                        '& > fieldset': {
                            border: '2px solid black',
                        },
                    },
                },
                minWidth: '150px',
            }}
            matchSuggestion={matchAutoCompleteSuggestion}
            onInputChange={(e) => setFilters({ q: e.target.value })}
        />
    )
}

export const CustomDivider = ({ sx }) => {
    return <Divider sx={sx} />
}

const horizontalTextFieldStyles = {
    root: {
        margin: '16px 0 0 0',
        overflowWrap: 'anywhere',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    source: {
        color: '#6d6d6d',
        flexShrink: 0,
    },
    field: {
        margin: '0 0 0 25px',
    },
}
export const HorizontalTextField = ({
    source,
    rowName,
    record = {},
    loadJson = false,
    truncate = false,
}) => {
    let fieldValue = loadJson
        ? JSON.stringify(get(record, source, null))
        : get(record, source, null)

    if (truncate && fieldValue?.length > 48) {
        fieldValue = fieldValue.slice(0, 48)
    }

    if (source.includes('LAST_CHANGE')) {
        fieldValue = fieldValue
            ? formatDate(fieldValue, false, 'M/d/yyyy, pp')
            : 'N/A'
    }

    return (
        <Box sx={horizontalTextFieldStyles.root}>
            <Typography sx={horizontalTextFieldStyles.source}>
                {' '}
                {rowName || source}{' '}
            </Typography>
            <Typography sx={horizontalTextFieldStyles.field} noWrap={truncate}>
                {fieldValue !== null ? fieldValue.toString() : 'N/A'}
            </Typography>
        </Box>
    )
}

export const HorizontalScheduleField = ({
    record = {},
    source,
    rowName,
    tenant,
    ...props
}) => {
    const [humanCron, setHumanCron] = useState()
    const schedule = get(record, source, 'N/A')
    let value
    useEffect(() => {
        try {
            if (isEmpty(schedule) || schedule === 'paused') {
                setHumanCron('')
            } else {
                parser.parseExpression(schedule)

                setHumanCron(
                    cronstrue.toString(schedule, {
                        throwExceptionOnParseError: true,
                    })
                )
            }
        } catch (err) {
            setHumanCron(err.toString())
        }
    }, [schedule])

    if (rowName === 'LAST SYNC') {
        value = formatDate(
            get(tenant, 'status.lastRun.completionTime', ''),
            false,
            'M/d/yyyy, pp'
        )
    } else if (rowName === 'NEXT SYNC') {
        value = nextSyncTime(schedule, tenant.paused, false, 'M/d/yyyy, pp')
    } else {
        value = SCHEDULEMAP[schedule] ? SCHEDULEMAP[schedule] : humanCron
    }

    return (
        <Box sx={horizontalTextFieldStyles.root}>
            <Typography sx={horizontalTextFieldStyles.source}>
                {' '}
                {rowName}{' '}
            </Typography>
            <Typography sx={horizontalTextFieldStyles.field}>
                {value}
            </Typography>
        </Box>
    )
}

/**
 *
 * @param releaseOption 'channel' or 'release'
 * @param setReleaseOption callback
 */
export const ReleaseOptionSelector = ({ releaseOption, setReleaseOption }) => (
    <RadioGroup
        row
        value={releaseOption}
        name="release-options-radio-buttons"
        onChange={(e) => setReleaseOption(e.target.value)}
    >
        <FormControlLabel
            value="release"
            control={<Radio />}
            label="Specific Release"
        />
        <FormControlLabel value="channel" control={<Radio />} label="Channel" />
    </RadioGroup>
)

/**
 * Using filteredReleases, this component fetches and renders a drop down of releases.
 * @param source
 * @param values - used to determine the integration id in tenant edit mode
 * @param disabled
 * @param onChange - optional additional onChange sideeffect
 * @returns {*}
 */
export const SelectReleasesInput = ({
    source,
    values,
    disabled = false,
    onChange = () => {},
}) => {
    // This component is used in both edit and create contexts.
    // Here we figure out which one we are in.
    const editProps = useEditContext()
    const createProps = useCreateContext()
    const { setValue } = useFormContext()
    const [resource, mode] = editProps.resource
        ? [editProps.resource, 'EDIT']
        : [createProps.resource, 'CREATE']

    const integrationId = useMemo(
        () => {
            if (resource === 'integrations') {
                return editProps.record.id
            } else if (resource === 'tenants') {
                return mode === 'CREATE'
                    ? values?.integration
                    : editProps.record.integration.id
            }
        },
        // we only need to memoize in tenant create, when the integraiton id lives in
        // form values and may change on the current page.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [values?.integration]
    )

    const { isLoading: loading, data: integrationReleases } = useGetList(
        'integrationreleases',
        {
            filter: { integration_id: integrationId || 0 },
            sort: { field: 'createdDate', order: 'DESC' },
            pagination: { page: 1, perPage: 500 },
        }
    )

    const integrationReleaseNames = sortedReleaseNames(integrationReleases)

    return (
        <SelectInput
            emptyText={'Select Release'}
            emptyValue={-1}
            source={source}
            choices={
                integrationId === undefined
                    ? [{ id: -1, name: 'N/A' }]
                    : integrationReleaseNames
            }
            sx={{
                width: '328px',
            }}
            variant="outlined"
            onChange={(ev) => {
                setValue(source, ev.target.value)
                // additional onChange prop supports side effects, like setting
                // the release channel to be null when a user sets a specific release
                onChange(ev)
            }}
            disabled={disabled || loading}
        />
    )
}

// Needed to pass wizard values to Cron component, so it can render Simple/Advance on mount
// Currently has singular use... could be generalized.
export const ChildrenWithProps = (props) =>
    React.Children.map(props.children, (child) => {
        return React.cloneElement(child, {
            values: props.values,
            methods: props.methods,
        })
    })

/**
 * Displays title in page -- mainly edit pages
 * @param title
 * @returns {*}
 * @constructor
 */
export const PageTitle = ({ title }) => {
    return (
        <Typography
            variant="h4"
            sx={{
                fontFamily: 'RobotoCondensedBold',
                margin: '20px 0 16px 0px',
            }}
        >
            {title}
        </Typography>
    )
}

const RunPhaseStyle = {
    undefined: { color: '#e4e4e4 ' },
    success: { color: '#3CF6C8' },
    error: { color: '#EB0000' },
    warning: { color: 'orange' },
}

const AnimatedProgressIcon = () => {
    const [progress, setProgress] = useState(0)
    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prev) => (prev >= 100 ? 0 : prev + 10))
        }, 800)
        return () => {
            clearInterval(timer)
        }
    }, [])
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', ml: '2px' }}>
            <CircularProgress
                variant="determinate"
                value={progress}
                thickness={22}
                size={22}
                sx={RunPhaseStyle.success}
            />
            <CircularProgress
                variant="determinate"
                value={100}
                thickness={5}
                size={22}
                sx={{ position: 'absolute', ...RunPhaseStyle.success }}
            />
        </Box>
    )
}

const RUN_STATUS_MAP = {
    Initializing: () => <PendingOutlined sx={RunPhaseStyle.success} />,
    'In Progress': () => <AnimatedProgressIcon />,
    Succeeded: () => <CheckCircle sx={RunPhaseStyle.success} />,
    'Failed (Platform Issue)': () => (
        <CancelOutlined sx={RunPhaseStyle.error} />
    ),
    'Failed (Integration Issue)': () => <Error sx={RunPhaseStyle.error} />,
    'Failed (Timeout)': () => <Error sx={RunPhaseStyle.error} />,
}

export const RunPhaseField = ({ source, addLabel = false, ...props }) => {
    const record = useRecordContext(props)

    const status = get(record, source)
    if (status === undefined) return null

    const Icon =
        RUN_STATUS_MAP[status] || (() => <Warning sx={RunPhaseStyle.warning} />)
    return (
        <span title={status}>
            <Icon />
            {addLabel && status}
        </span>
    )
}

export const DisconnectDialog = (props) => {
    const { open, handleClose, connector, disconnect } = props

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xs">
            <DialogTitle> {get(connector, 'label', connector.id)} </DialogTitle>
            <DialogContent sx={{ width: '300px' }}>
                <DialogContentText>
                    Are you sure you want to disconnect?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        disconnect()
                        handleClose()
                    }}
                    sx={{ color: '#fc3b40' }}
                >
                    Disconnect
                </Button>
                <Button className="clearButton" onClick={handleClose}>
                    {' '}
                    Cancel{' '}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

//--------------------------------------------------- FUNCTIONS --------------------------------------------------- //

export const filterReleases = (releases, integrationIn) => {
    return integrationIn && !isEmpty(releases.data)
        ? pickBy(releases.data, (key) => {
              return key.integrationId === integrationIn
          })
        : {}
}

/**
 * Get connectors related to tenant
 * @param tenant
 * @returns {*}
 */
export const useConnectors = (integrationConnectors) => {
    const connectorIds = (integrationConnectors || []).map((connector) => {
        return connector.name
    })

    const { data, isLoading, error } = useGetMany('connectors', {
        ids: [connectorIds],
    })
    if (error) {
        console.debug('Error fetching connectors: ', error)
        // TODO what would we like to display in case of fetching errors? Can be reused across the product
        return null
    }

    const filteredConnectors = data?.filter((connector) => {
        return connectorIds.includes(get(connector, 'name', ''))
    })

    return !isLoading ? filteredConnectors : {}
}

/**
 * Get oldest user related to tenant
 * @param tenant
 * @returns {*}
 */
export const useTenantUserInfo = (tenant) => {
    const uniqueIds = Array.from(
        new Set(
            Object.entries(get(tenant, 'connectedUsers', {})).map(
                ([_, value]) => {
                    return get(value, 'username')
                }
            )
        )
    )
    const { data, error, loaded } = useGetOne('userinfo', { id: uniqueIds })
    if (error) {
        console.debug('Error fetching userinfo: ', error)
        return null
    }
    return loaded ? data : {}
}

/**
 * Get all user info
 * @param None
 * @returns {*}
 */
export const useTenantUserInfoList = () => {
    const { data, error, loaded } = useGetList('userinfo')
    if (error) {
        console.debug('Error fetching userinfo: ', error)
        return null
    }
    return loaded ? { data: data, loaded: loaded } : { loaded: loaded }
}

/**
 * Get all connectors (with an option to filter by name)
 *
 * @param filter - {'id': [connectorName1, connectorName2, ...]}
 * @returns {*}
 */
export const useConnectorsList = (filter = {}) => {
    const { data, error, isLoading } = useGetList('connectors', {
        filter: filter,
    })
    if (error) {
        console.debug('Error fetching connectors: ', error)
        return { data: null, isLoading }
    }
    return { data, isLoading }
}

/**
 * Parses sorts the filtered release names by creation date.....
 * @param releaseData
 * @returns {*}
 */
export const sortedReleaseNames = (releaseData) => {
    const releaseList = !isEmpty(releaseData) && Object.keys(releaseData)

    return releaseList.length > 0
        ? sortBy(
              releaseList.map((id) => {
                  let tag = releaseData[id].tag
                  let name = releaseData[id].name
                  let createdDate = releaseData[id].createdDate
                  let longName = tag ? tag + ' - ' + name : name
                  return {
                      id: releaseData[id].id,
                      name: longName,
                      tag: tag,
                      createdDate: createdDate,
                  }
              }),
              (release) => release.createdDate
          ).reverse()
        : [{ id: -1, name: 'N/A' }]
}

// custom hook for getting previous value
export function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

// Display Tenant's customer field by using customerColumnPath eg:
// `connectedUsers.${account}.attributes.${account}.${uidDisplay}` which is the path in the tenant object to this field
export const CustomerField = (props) => {
    const record = useRecordContext(props)
    return <span> {get(record, props.source, 'N/A')} </span>
}

export const uploadFile = async (path, file, type) => {
    const data = new FormData()
    data.append('uploaded_file', file)
    data.append('file_type', type)
    return fetch(`${API_URI}/files/${path}`, {
        method: 'POST',
        headers: HEADERS(sessionStorage.getItem('token')),
        body: data,
    }).then((response) => {
        return response.ok ? response.json() : 'error'
    })
}

export const deleteFile = async (path) => {
    return fetch(`${API_URI}/files/${path}`, {
        method: 'DELETE',
        headers: HEADERS(sessionStorage.getItem('token')),
    })
        .then((response) => response.json())
        .catch((error) => console.log(`error deleting file ${error}`))
}

//Use files endpoint to upload an image
export const uploadImage = async (resource, file, resourceId, type) => {
    return await uploadFile(`${resource}/${resourceId}`, file, type)
}
