import React, { useState } from 'react'
import { EmptyState } from '../../react_admin/EmptyState'

import {
    Datagrid,
    TextField,
    DateField,
    Filter,
    ReferenceInput,
    TopToolbar as RATopToolbar,
    ListContextProvider,
    useListFilterContext,
} from 'react-admin'
import { useListController } from 'ra-core'
import {
    Button,
    Divider,
    Typography,
    TextField as MuiTextField,
    Box,
} from '@mui/material'
import {
    RunPhaseField,
    FilterSelectInput,
    FilterAutoCompleteInput,
} from '../../common'
import { format } from 'date-fns'
import { JOBS_PER_PAGE, API_MAX_PER_PAGE } from '../../../appConfigs'
import ListPagination from '../List/ListPagination'
import LinkField from '../../LinkField'
import { connect } from 'react-redux'
import { DateTimePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

export const JobDataGrid = ({
    uidDisplay,
    customerColumnPath,
    bulkActionButtons,
    linkToTenant = true,
    ...props
}) => {
    return (
        <Datagrid
            {...props}
            rowClick="show"
            empty={
                <EmptyState
                    emptyStateText={
                        'Welcome to Pandium!\nCreate a tenant to view and manage activity.'
                    }
                />
            }
            {...props}
            data={props.data}
            sx={{
                border: '1px solid #DBDEE5',
                borderTop: '1px solid #DBDEE5',
            }}
            bulkActionButtons={false}
        >
            <TextField
                source="jobSpec.integration.name"
                label={'INTEGRATION'}
            />
            <TextField source="mode" label={'MODE'} />
            <TextField source="trigger" label={'TRIGGER'} />
            <TextField source="jobSpec.tenant.name" label="TENANT" />
            <DateField source="startedDate" label="STARTED" showTime />
            <DateField source="completedDate" label="COMPLETED" showTime />
            <RunPhaseField source="status.phase" label="STATUS" />
            <LinkField
                // a.detailLink is defined in the theme styles in appConfigs.js, to allow us to target
                // this field for the hover effect
                className="detailLink"
                basePath="runs"
                redirect="show"
                sortable={true}
                variant="outlined"
                text="SHOW LOGS"
            />
        </Datagrid>
    )
}

export const JobFilter = ({
    storeKey,
    isSuperUser,
    showAllFilters = true,
    ...props
}) => {
    const pandiumIntegrationFilter = !isSuperUser
        ? { type__not_equal: 'PANDIUM' }
        : {}
    const pandiumTenantFilter = !isSuperUser
        ? { integration__type__not_equal: 'PANDIUM' }
        : {}
    const { filterValues, setFilters } = useListFilterContext({ storeKey })
    // the DateTimePickers need special state vars to hold the entire datetime object,
    // there is lag if the string that is stored in filterValues is passed to the value prop on the component.
    const [startDateValue, setStartDateValue] = useState(
        filterValues.started_date__gt
            ? dayjs(filterValues.started_date__gt)
            : null
    )
    const [endDateValue, setEndDateValue] = useState(
        filterValues.started_date__lt
            ? dayjs(filterValues.started_date__lt)
            : null
    )
    const [integrationId, setIntegrationId] = useState(null)
    return (
        <Box
            sx={{
                display: 'flex',
                mt: 1,
                mb: 3,
                spacing: 0,
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    flexShrink: 1,
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    mt: 0.5,
                }}
            >
                <Filter {...props}>
                    {showAllFilters && (
                        <ReferenceInput
                            source="integration_id"
                            reference="integrations"
                            filter={pandiumIntegrationFilter}
                            alwaysOn
                            label="Integration"
                            onChange={(e) => setIntegrationId(e.target.value)}
                            perPage={API_MAX_PER_PAGE}
                        >
                            <FilterAutoCompleteInput
                                optionText="name"
                                setFilters={setFilters}
                            />
                        </ReferenceInput>
                    )}
                    {showAllFilters && (
                        <ReferenceInput
                            source="tenant_id"
                            reference="tenants"
                            alwaysOn
                            label="Tenant"
                            perPage={API_MAX_PER_PAGE}
                            sort={{ field: 'name', order: 'ASC' }}
                            filter={
                                integrationId
                                    ? {
                                          integration_id: integrationId,
                                          ...pandiumTenantFilter,
                                      }
                                    : pandiumTenantFilter
                            }
                        >
                            <FilterAutoCompleteInput
                                optionText="name"
                                setFilters={setFilters}
                            />
                        </ReferenceInput>
                    )}
                    <FilterSelectInput
                        source="mode"
                        alwaysOn
                        choices={[
                            { id: 'init', name: 'Init' },
                            { id: 'normal', name: 'Normal' },
                            { id: 'webhook', name: 'Webhook' },
                        ]}
                        filterValues={filterValues}
                        setFilters={setFilters}
                    />
                </Filter>
            </Box>
            {/* the filter component above only works with RA components, so the mui DateTimePickers are outside of it */}
            {showAllFilters && (
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        spacing: 0,
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <DateTimePicker
                        label="Start"
                        value={startDateValue || null}
                        onChange={(newValue) => {
                            setFilters({
                                ...filterValues,
                                started_date__gt: newValue?.toString(),
                            })
                            setStartDateValue(newValue)
                        }}
                        disableFuture
                        minDateTime={dayjs().subtract(1, 'month')}
                        maxDateTime={endDateValue}
                        renderInput={(params) => (
                            <MuiTextField
                                {...params}
                                variant="outlined"
                                sx={{
                                    '& > div.MuiInputBase-root': {
                                        height: '38px',
                                        borderRadius: 0,
                                        width: 'auto',
                                        '&:hover': {
                                            '& > fieldset': {
                                                border: '2px solid black',
                                            },
                                        },
                                    },
                                    minWidth: '150px',
                                }}
                            />
                        )}
                    />
                    <DateTimePicker
                        label="End"
                        value={endDateValue || null}
                        onChange={(newValue) => {
                            setFilters({
                                ...filterValues,
                                started_date__lt: newValue?.toString(),
                            })
                            setEndDateValue(newValue)
                        }}
                        disableFuture
                        minDateTime={
                            startDateValue || dayjs().subtract(1, 'month')
                        }
                        renderInput={(params) => (
                            <MuiTextField
                                {...params}
                                variant="outlined"
                                sx={{
                                    '& > div.MuiInputBase-root': {
                                        height: '38px',
                                        borderRadius: 0,
                                        width: 'auto',
                                        '&:hover': {
                                            '& > fieldset': {
                                                border: '2px solid black',
                                            },
                                        },
                                    },
                                    minWidth: '150px',
                                }}
                            />
                        )}
                    />
                </Box>
            )}
            <Button
                onClick={() => {
                    setFilters({})
                    setStartDateValue(null)
                    setEndDateValue(null)
                }}
                sx={{
                    fontFamily: 'RobotoCondensedBold',
                    letterSpacing: '1.5px',
                    color: '#626FFC',
                    mt: '2px',
                    ml: 2,
                }}
            >
                Clear All
            </Button>
        </Box>
    )
}

const toolbarStyles = {
    timestamp: {
        fontSize: '11px',
        color: 'grey',
        flexGrow: '4',
        alignSelf: 'center',
        marginLeft: '10px',
    },
}

const JobsListTopToolbar = () => {
    const now = new Date()
    const timestamp = format(now, 'M/dd/yyyy, h:mm:ss aa')
    return (
        <RATopToolbar>
            <h1>Activity</h1>
            <Typography
                sx={toolbarStyles.timestamp}
            >{`Last updated: ${timestamp}`}</Typography>
        </RATopToolbar>
    )
}

const JobsList = (props) => {
    const pandiumFilter = !props.isSuperUser
        ? { integration__type__not_equal: 'PANDIUM' }
        : {}
    const controllerProps = useListController({
        perPage: JOBS_PER_PAGE,
        sort: { field: 'startedDate', order: 'DESC' },
        filter: pandiumFilter,
        actions: null,
        storeKey: 'mainList',
        ...props,
    })

    return (
        <>
            {JobsListTopToolbar && (
                <>
                    <JobsListTopToolbar />
                    <Divider className="pageHead" />
                </>
            )}
            <ListContextProvider value={controllerProps}>
                <JobFilter storeKey="mainList" />
                <JobDataGrid />
                <ListPagination />
            </ListContextProvider>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        isSuperUser: state.org.isSuperUser,
    }
}

export const JobList = connect(mapStateToProps)(JobsList)
